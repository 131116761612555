<template>
  <!-- 线上课程 -->
  <div class="virtualsimulation">
    <div class="head">
      <div class="searchcontain">
        <input
          v-model="queryParams.keyword"
          class="elinput"
          placeholder="请输入课程名称"
          type="text"
        />
        <div class="searchbt" @click="searchbt">搜索</div>
      </div>
    </div>
    <div class="itemcontain">
      <template v-if="datalist">
        <div class="item" v-for="(item, index) in datalist" :key="index">
          <el-image
            :class="item.cover ? 'elimage' : 'elimage1'"
            :src="item.cover ? item.cover : image11"
            @click="todetail(item)" @error="handleError"
            style="cursor: pointer"
          />
          <div class="item-right">
            <div class="title" @click="todetail(item)" style="cursor: pointer">
              {{ item.name }}
            </div>

            <el-select
              v-if="item.course_semester_list.length > 0"
              v-model="item.course_semester_sn"
              class="elinput"
              placeholder="请选择"
              @change="setCourseSemester"
            >
              <el-option
                v-for="item in item.course_semester_list"
                :key="item.course_semester_id"
                :label="item.course_semester_name"
                :value="item.course_semester_sn"
              />
            </el-select>
            <div
              class="nameicon"
              @click="todetail(item)"
              style="cursor: pointer"
            >
              <!-- <el-image class="icon" :src="icon1" /> -->
              <div class="name">
                <span>主讲老师：</span>
                {{ item.teacher_user_info ? item.teacher_user_info.name : "" }}
              </div>
            </div>

            <div class="bottomcontain" v-if="Number(item.child_type) === 0">
              <div class="left" @click="todetail(item)" style="cursor: pointer">
                <div class="contain">
                  <!-- <el-image class="icon" :src="icon2" /> -->
                  <div class="name">学习进度</div>
                  <div class="elprogress">
                    <el-progress
                      :stroke-width="15"
                      :percentage="
                        item.study_progress
                          ? parseInt(item.study_progress * 100)
                          : 0
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="item" @click="resultsbt(item)">
                  <el-image class="elimage" :src="image1" />
                  <div class="name">课程成绩</div>
                </div>
                <!-- <div class="line"></div> -->
                <div class="item" @click="interactiveqabt(item)">
                  <el-image class="elimage" :src="image2" />
                  <div class="name">互动问答</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="elpagination" v-if="total > 0">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="queryParams.page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getmycourselist, getCourseSemesterSimpleInfo } from "@/api/course";
export default {
  name: "virtualsimulation",
  components: {},
  created() {
    this.getmycourselist();
  },
  data() {
    return {
      total: 0,
      queryParams: {
        keyword: "",
        page: 1,
        per_page: 10,
      },
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/center/时间 钟表.png"),
      image1: require("@/assets/new/课程.实验成绩.png"),
      image2: require("@/assets/coursedetails/center/answers.png"),
      image11: require("@/assets/defaultImage.png"),
      datalist: [],
    };
  },
  methods: {
     handleError(e) {
      // 当图片加载失败时，将图片地址设置为默认图片
      e.target.src = this.image11;
      // 可以添加额外的处理，比如提示用户或者进行日志记录
    },
    searchbt() {
      this.queryParams.page = 1;
      this.getmycourselist();
    },
    changePage(val) {
      this.queryParams.page = val;
      this.getmycourselist();
    },
    resultsbt(item) {
      this.$router.push({
        path: "/home/personalcenter/courseresults",
        query: {
          id: item.id,
          course_id: item.course_id,
          course_sn: item.course_sn,
          course_semester_sn: item.course_semester_sn,
          study_progress: item.study_progress,
        },
      });
    },
    reportbt() {
      this.$router.push({
        path: "/home/personalcenter/experimentalreport",
      });
    },
    // 互动问答
    interactiveqabt(item) {
      this.$router.push({
        path: "/home/personalcenter/courseinteractiveqa",
        query: {
          name: item.name,
          course_sn: item.course_sn,
          course_semester_sn: item.course_semester_sn,
        },
      });
    },
    // 课程列表
    getmycourselist() {
      getmycourselist(this.queryParams)
        .then((response) => {
          this.total = response.data.total;
          this.datalist = response.data.data.map((element) => {
            const cslist = element.course_semester_list || [];
            // .filter(el => (el.start_year && el.end_year && el.semester_nper));
            element.course_semester_list = cslist.map((item) => {
              const year_run =
                (item.start_year ? item.start_year : "") +
                "-" +
                (item.end_year ? item.end_year : "");
              item.course_semester_name =
                year_run +
                (item.semester_nper ? " 第" + item.semester_nper + "学期" : "");
              return item;
            });
            return element;
          });
        })
        .catch(() => {});
    },
    // 获取实验项目详情数据
    setCourseSemester(e) {
      const params = { course_semester_sn: e };
      getCourseSemesterSimpleInfo(params)
        .then((response) => {
          if (response.code === 0 && response.data) {
            const resData = Object.assign({}, response.data);
            for (let i = 0; i < this.datalist.length; i++) {
              if (this.datalist[i].course_id === resData.course_id) {
                this.datalist[i].name = resData.name; // 课程名称
                this.datalist[i].course_semester_sn =
                  resData.course_semester_sn; // 课程SN
                this.datalist[i].home_cover = resData.cover; // 课程封面
                this.datalist[i].teacher_user_info = resData.teacher_user_info; // 教师
                this.datalist[i].study_progress = resData.study_progress;
              }
            }
          }
        })
        .catch(() => {});
    },
    // 进入详情页
    todetail(item) {
      window.localStorage.setItem("menuId", 2);
      this.$store.dispatch("setmenuid", 2);
      if (Number(item.child_type)) {
        // 耕读类型
        this.$router.push({
          path: "/home/wisdompracticeteaching/coursedetails",
          query: {
            course_sn: item.course_sn,
            courseType: item.child_type,
          },
        });
      } else {
        //已经加入课程
        this.$router.push({
          path: "/home/wisdompracticeteaching/courselearning",
          query: {
            course_sn: item.course_sn,
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .virtualsimulation {
    padding: 20px;

    .head {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 22px;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #288add;
        user-select: none;
        white-space: nowrap;
      }

      .searchcontain {
        // display: flex;
        // justify-content: flex-end;
        // align-items: center;
        // border: 1px solid red;
        position: relative;

        .elinput {
          width: 260px;
          height: 28px;
          border-radius: 25px;
          padding-left: 20px;
          border: 1px solid #3d84ff;

          &:focus {
            outline: none;
          }
        }

        .searchbt {
          position: absolute;
          top: 0px;
          right: 0px;
          margin-left: 17px;
          width: 85px;
          height: 32px;
          background: #3d84ff;
          border-radius: 25px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          user-select: none;

          &:active {
            background: #26b7ffa9;
          }
        }
      }
    }

    .itemcontain {
      // border: 1px solid red;
      // margin-top: 30px;
      // height: 234px;
      background: #ffffff;
      // padding: 20px;
      box-sizing: border-box;

      .item {
        // border: 1px solid red;
        // height: 200px;
        // width: 100%;
        // display: flex;
        // justify-content: space-between;
        // align-content: center;
        margin-bottom: 20px;

        .elimage {
          width: 100%;
          height: 100%;
          // background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
          background: aliceblue;
          border-radius: 4px;
        }
        .elimage1 {
          width: 100%;
          height: 100%;
          // background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
          background: aliceblue;
          border-radius: 4px;
          height: 250px;
          ::v-deep .el-image__inner {
            width: 70%;
            position: relative;
            left: 15%;
          }
        }

        .item-right {
          // border: 1px solid red;
          height: 100%;
          flex: 1;
          margin-top: 15px;
          // margin-left: 30px;

          .title {
            font-size: 20px;
            font-weight: 500;
            color: #222222;
            margin-bottom: 20px;
          }

          .nameicon {
            margin-top: 24px;
            display: flex;
            justify-content: flex-start;
            align-content: center;

            .icon {
              margin-right: 10px;
              width: 23px;
              height: 20px;
            }

            .name {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }

          .bottomcontain {
            // border: 1px solid red;
            margin-top: 15px;
            // height: 85px;
            // display: flex;
            // justify-content: space-between;
            // align-content: flex-end;

            .left {
              // border: 1px solid red;
              // display: flex;
              // flex-direction: column;
              // justify-content: flex-end;
              // align-items: center;

              .contain {
                //   border: 1px solid red;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                // width: 300px;
                //   position: relative;
                .icon {
                  margin-right: 10px;
                  width: 22px;
                  height: 22px;
                }

                .name {
                  margin-right: 10px;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                }

                .elprogress {
                  margin-left: 10px;
                  width: 75%;
                }

                .time {
                  font-size: 15px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #222222;
                }
              }
            }

            .right {
              // border: 1px solid red;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 30px;

              .item {
                // border: 1px solid red;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                user-select: none;
                cursor: pointer;
                width: 160px;

                .elimage {
                  width: 57px;
                  height: 57px;
                  border-radius: 50%;
                }

                .name {
                  font-size: 16px;
                  font-weight: 500;
                  color: #666666;
                  margin-top: 20px;
                }
              }

              .line {
                margin-left: 40px;
                margin-right: 40px;
                height: 85px;
                border-right: 1px solid #e7e7e7;
              }
            }
          }
        }
      }
    }

    .elpagination {
      margin-top: 68px;
      display: flex;
      justify-content: center;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }

    ::v-deep .el-progress-bar {
      margin-right: -70px;
    }

    ::v-deep .el-progress__text {
      float: right;
    }
    ::v-deep .el-input__inner {
      border-radius: 40px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .virtualsimulation {
    padding: 20px;

    .head {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 22px;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #288add;
        user-select: none;
        white-space: nowrap;
      }

      .searchcontain {
        // display: flex;
        // justify-content: flex-end;
        // align-items: center;
        // border: 1px solid red;
        position: relative;

        .elinput {
          width: 260px;
          height: 28px;
          border-radius: 25px;
          padding-left: 20px;
          border: 1px solid #3d84ff;

          &:focus {
            outline: none;
          }
        }

        .searchbt {
          position: absolute;
          top: 0px;
          right: 0px;
          margin-left: 17px;
          width: 85px;
          height: 32px;
          background: #3d84ff;
          border-radius: 25px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          user-select: none;

          &:active {
            background: #26b7ffa9;
          }
        }
      }
    }

    .itemcontain {
      // border: 1px solid red;
      // margin-top: 30px;
      // height: 234px;
      background: #ffffff;
      // padding: 20px;
      box-sizing: border-box;

      .item {
        // border: 1px solid red;
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 20px;

        .elimage {
          width: 313px;
          height: 100%;
          // background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
          background: aliceblue;
          border-radius: 4px;
        }
        .elimage1 {
          width: 313px;
          height: 100%;
          // background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
          background: aliceblue;
          border-radius: 4px;
          // height: 250px;
          ::v-deep .el-image__inner {
            width: 70%;
            position: relative;
            left: 15%;
          }
        }

        .item-right {
          // border: 1px solid red;
          height: 100%;
          flex: 1;
          margin-left: 30px;

          .title {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
            margin-bottom: 30px;
          }

          .nameicon {
            margin-top: 24px;
            display: flex;
            justify-content: flex-start;
            align-content: center;

            .icon {
              margin-right: 10px;
              width: 23px;
              height: 20px;
            }

            .name {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }

          .bottomcontain {
            // border: 1px solid red;
            margin-top: -70px;
            // height: 85px;
            display: flex;
            justify-content: space-between;
            align-content: flex-end;

            .left {
              // border: 1px solid red;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;

              .contain {
                //   border: 1px solid red;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                // width: 300px;
                //   position: relative;
                .icon {
                  margin-right: 10px;
                  width: 22px;
                  height: 22px;
                }

                .name {
                  margin-right: 10px;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                }

                .elprogress {
                  margin-left: 10px;
                  width: 400px;
                }

                .time {
                  font-size: 15px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #222222;
                }
              }
            }

            .right {
              // border: 1px solid red;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .item {
                // border: 1px solid red;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                user-select: none;
                cursor: pointer;
                width: 160px;

                .elimage {
                  width: 57px;
                  height: 57px;
                  border-radius: 50%;
                }

                .name {
                  font-size: 16px;
                  font-weight: 500;
                  color: #666666;
                  margin-top: 20px;
                }
              }

              .line {
                margin-left: 40px;
                margin-right: 40px;
                height: 85px;
                border-right: 1px solid #e7e7e7;
              }
            }
          }
        }
      }
    }

    .elpagination {
      margin-top: 68px;
      display: flex;
      justify-content: center;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }

    ::v-deep .el-progress-bar {
      margin-right: -70px;
    }

    ::v-deep .el-progress__text {
      float: right;
    }
    ::v-deep .el-input__inner {
      border-radius: 40px;
    }
  }
}
</style>
