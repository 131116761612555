<template>
  <el-form
    :model="ruleForm"
    status-icon
    :rules="rules"
    ref="ruleForm"
    label-position="right"
    label-width="120px"
    class="elform"
  >
    <el-form-item
      label="旧邮箱:"
      prop="oldMail"
      v-if="
        ruleForm.oldMail !== 'null' &&
        ruleForm.oldMail !== '' &&
        ruleForm.oldMail !== null
      "
    >
      <el-input v-model="ruleForm.oldMail" disabled></el-input>
    </el-form-item>
    <el-form-item label="新邮箱:" prop="email">
      <el-input v-model="ruleForm.email" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="验证码" prop="email_code">
      <el-input
        placeholder="请输入验证码"
        v-model="ruleForm.email_code"
        type="text"
      >
        <template slot="append" v-if="show">
          <el-button
            type="text"
            class="code_text"
            @click="getCode()"
            :loading="get_code"
            >
            获取验证码
            </el-button
          >
        </template>
        <template slot="append" v-if="!show">
          <div class="getCode">
            <span>{{ count + "s" }}后重发</span>
          </div>
        </template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { edituserEmail, sendemailcode } from "@/api/user";
import md5 from "js-md5";
export default {
  name: "change-mail",
  components: {},
  props: {
    close: {
      type: Function,
    },
    isIdent: {
      type: String,
      default: "",
    },
  },
  data() {
    const validateMail = (rule, value, callback) => {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {
        this.sendCode = true;
        callback();
      } else {
        this.sendCode = false;
        callback(new Error("邮箱格式错误!"));
      }
    };
    const codeCheck = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入正确的验证码!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldMail: "",
        email: "",
        email_code: "",
      },
      rules: {
        // oldMail: [{ required: true, validator: validateMail, trigger: "blur" }],
        email: [{ required: true, validator: validateMail, trigger: "blur" }],
        email_code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            required: true,
            validator: codeCheck,
          },
        ],
      },
      show: true,
      count: "",
      timer: null,
      get_code: false,
      sendCode: false,
    };
  },
  created() {
    if (sessionStorage.getItem("oldEmail")) {
      this.ruleForm.oldMail = sessionStorage.getItem("oldEmail");
    }
  },
  mounted() {},
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changeEmail({
            new_email: this.ruleForm.email,
            code: this.ruleForm.email_code,
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeEmail(params) {
      edituserEmail(params)
        .then((response) => {
          if (response.code === 0) {
            this.$message.success("更换邮箱成功!");
            if (this.isIdent == 3) {
              this.$emit("setNewEmail", this.ruleForm.email);
            }
            this.close();
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    getCode() {
      if (this.ruleForm.email === "") {
        this.$message.warning("请输入新邮箱!");
        return false;
      }
      if (!this.sendCode) {
        return false;
      }
      this.get_code = true;
      const time = new Date().getTime();
      const params = {
        email: this.ruleForm.email,
        sign: md5(md5(this.ruleForm.email) + time),
        time: time,
      };
      sendemailcode(params)
        .then((res) => {
          this.get_code = false;
          if (res.code === 0) {
            this.$message.success("验证码发送成功!");
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
            this.ruleForm.email_code = res.data;
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.elform {
  padding: 40px;
  width: 500px;
}
::v-deep .code_text {
  color: #409eff !important;
  padding: 10px;
}
::v-deep .getCode {
  color: #909399;
}
</style>
