<template>
  <!-- 教学成绩 -->
  <div class="virtualsimulation">
    <div class="item">
      <div>平时成绩：</div>
      <div>{{ datainfo.usual_score }}</div>
    </div>
    <div class="item">
      <div>期中成绩：</div>
      <div>{{ datainfo.midterm_score }}</div>
    </div>
    <div class="item">
      <div>期末成绩：</div>
      <div>{{ datainfo.finalterm_score }}</div>
    </div>
    <div class="item">
      <div>老师评分：</div>
      <div>{{ datainfo.teacher_mark_score }}</div>
    </div>
    <div class="line"></div>
    <div class="item">
      <div>总成绩：</div>
      <div>{{ datainfo.total_score }}</div>
    </div>
  </div>
</template>

<script>
import { getmyperformancelist } from "@/api/courselibstudy"
export default {
  name: "Score",
  components: {},
  data () {
    return {
      datainfo: {},
    }
  },
  methods: {
    initdata (item, semester_id) {
      this.getmyperformancelist({
        course_lib_id: item.id,
        semester_id: semester_id,
      })
    },
    getmyperformancelist (params) {
      getmyperformancelist(params)
        .then((response) => {
          if (response.code === 0) {
            this.datainfo = response.data || {}
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  padding: 60px;
  padding-left: 30px;
  padding-right: 100px;
  padding-top: 10px;
  .item {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #222222;
  }
  .line {
    margin-left: -30px;
    margin-right: -100px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cccccc;
  }
}
</style>
