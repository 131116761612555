<template>
  <!-- 虚拟仿真实验 -->
  <div class="virtualsimulation">
    <div class="itemcontain">
      <div class="head">
        <div class="searchcontain">
          <input
            class="elinput"
            v-model="queryParams.keyword"
            placeholder="请输入虚拟仿真实验名称"
            type="text"
          />
          <div class="searchbt" @click="searchbt">搜索</div>
        </div>
      </div>
      <div class="item" v-for="(item, index) in vrlist" :key="index">
        <div class="elimage_img">
          <div class="elimage_img_1">
            <el-image
              :class="item.cover ? 'elimage1' : 'elimage'"
              :src="item.cover ? item.cover : image11"
              @click="todetail(item.id)"
              style="cursor: pointer"
              @error="handleError"
            />
          </div>
        </div>
        <div class="item-right">
          <div class="title" @click="todetail(item.id)" style="cursor: pointer">
            {{ item.name }}
          </div>
          <div
            class="nameicon"
            @click="todetail(item.id)"
            style="cursor: pointer"
          >
            <!-- <el-image class="icon" :src="icon1" /> -->
            <div class="name">负责人：</div>
            <div class="name">
              {{ item.leader_name ? item.leader_name?.toString() : "" }}
            </div>
          </div>
          <div
            class="nameicon"
            @click="todetail(item.id)"
            style="cursor: pointer"
          >
            <!-- <el-image class="icon" :src="image10" /> -->
            <div class="name">实验操作次数：</div>
            <div class="name">
              {{ item.operation_count ? item.operation_count : "" }}
            </div>
          </div>
          <!-- <div class="nameicon" @click="todetail(item.id)" style="cursor: pointer">
              <div class="name">完成度：</div>
              <div class="name">100%</div>
            </div> -->
          <div class="bottomcontain">
            <div
              class="left"
              @click="todetail(item.id)"
              style="cursor: pointer"
            >
              <div class="contain">
                <!-- <el-image class="icon" :src="icon2" /> -->
                <div class="name">累计实验时长：</div>
                <div class="time">{{ item.duration }}</div>
              </div>
            </div>
            <div class="right">
              <!-- <div class="item" @click="reportbt(item)">
                  <el-image class="elimage" :src="image1" />
                  <div class="name">实验报告</div>
                </div>-->
              <div class="item" @click="resultsbt(item)">
                <el-image class="elimage" :src="image2" />
                <div class="name">实验记录</div>
              </div>
              <div class="item" @click="interactiveqabt(item)">
                <el-image class="elimage" :src="image3" />
                <div class="name">互动问答</div>
              </div>
              <div class="item" @click="experimentcertificatebt(item)">
                <el-image class="elimage" :src="image4" />
                <div class="name">实验证书</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="elpagination" v-if="total < 0">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="queryParams.page"
        @current-change="pageChange"
      ></el-pagination>
    </div>

    <Customdialog
      ref="customdialog"
      title="虚拟仿真实验记录"
      type="table"
      :showclose="true"
      width="60%"
    >
      <div slot="dialogbody" class="dialogbody">
        <Scoretable ref="scoretable"></Scoretable>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getVRMyList } from "@/api/vr";

import Customdialog from "@/components/customdialog.vue";
import Scoretable from "./scoretable.vue";
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
    Scoretable,
  },
  data() {
    return {
      vrlist: [],
      total: 0,
      queryParams: {
        keyword: "",
        page: 1,
        per_page: 10,
      },
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/center/时间 钟表.png"),
      image1: require("@/assets/coursedetails/center/report.png"),
      image2: require("@/assets/coursedetails/center/step.png"),
      image3: require("@/assets/coursedetails/center/answers.png"),
      image4: require("@/assets/coursedetails/center/certificate.png"),

      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
      image09: require("@/assets/data-all.png"),
      image10: require("@/assets/pie-two.png"),
      image11: require("@/assets/vr.png"),
    };
  },
  created() {
    this.getVRMyList();
  },
  methods: {
    handleError(e) {
      // 当图片加载失败时，将图片地址设置为默认图片
      e.target.src = this.image11;
      // 可以添加额外的处理，比如提示用户或者进行日志记录
    },
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    resultsbt(item) {
      this.$refs.customdialog.dialogopenbt();
      this.$nextTick(() => {
        this.$refs.scoretable.getdata(item);
      });
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val;
      this.getVRMyList();
    },
    reportbt(item) {
      this.$router.push({
        path: "/home/personalcenter/vexperimentalreport",
        query: {
          vr_id: item.id,
        },
      });
    },
    todetail(id) {
      window.localStorage.setItem("menuId", 1);
      this.$store.dispatch("setmenuid", 1);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails",
        query: {
          id: id,
        },
      });
    },
    searchbt() {
      this.queryParams.page = 1;
      this.getVRMyList();
    },
    interactiveqabt(item) {
      this.$router.push({
        path: "/home/personalcenter/interactiveqa",
        query: {
          qa_id: item.id,
          name: item.name,
        },
      });
    },
    experimentcertificatebt(item) {
      this.$router.push({
        path: "/home/personalcenter/experimentcertificate",
        query: {
          vr_id: item.id,
        },
      });
    },

    getVRMyList() {
      this.vrlist = [];
      getVRMyList(this.queryParams)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.total = response.data.total;
            response.data.data.forEach((item) => {
              let h = 0;
              let m = 0;
              let s = 0;
              let total = 1;
              if (Object.keys(item.duration).length) {
                h = item.duration?.hour ? item.duration?.hour + " 时 " : "";
                m = item.duration?.minute ? item.duration?.minute + " 分 " : "";
                s = item.duration?.sec ? item.duration?.sec + " 秒 " : "";
              } else {
                total = 0;
              }
              this.vrlist.push({
                cover: item.cover,
                duration: total ? h + m + s : total + " 秒 ",
                id: item.id,
                operation_count: item.operation_count,
                status: item.status,
                name: item.name,
                leader_name: item.leader_name,
              });
            });
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .virtualsimulation {
    .itemcontain {
      background: #ffffff;
      padding: 10px;

      .head {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;

        .head-title {
          font-size: 20px;
          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .item {
        // height: 170px;
        width: 100%;
        // display: flex;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .elimage_img {
          background: aliceblue;
          min-height: 220px;

          .elimage_img_1 {
            width: 100%;
            text-align: center;
            margin: 0 auto;
            // padding-top: 5%;

            .elimage {
              width: 55%;
              height: 100%;
              // background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
              border-radius: 4px;
            }

            .elimage1 {
              width: 100%;
              height: 100%;
              // background: linear-gradient(0deg, #9778af 0%, #ffffff 100%);
              border-radius: 4px;
            }
          }
        }

        .item-right {
          height: 100%;
          flex: 1;
          margin-top: 15px;
          // margin-left: 30px;

          .title {
            font-size: 20px;
            font-weight: 500;
            color: #222222;
          }

          .nameicon {
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              margin-right: 10px;
              width: 23px;
              height: 20px;
            }

            .name {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
            }
          }

          .bottomcontain {
            margin-top: 10px;
            // height: 85px;
            // display: flex;
            // justify-content: space-between;
            // align-items: flex-end;

            .left {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              // align-items: center;
              margin-bottom: 10px;

              .contain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                // top: -60px;

                .icon {
                  margin-right: 10px;
                  width: 22px;
                  height: 22px;
                }

                .name {
                  margin-right: 10px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333;
                }

                .time {
                  font-size: 15px;
                  font-weight: 400;
                  color: #222222;
                }
              }
            }

            .right {
              display: flex;
              // justify-content: flex-start;
              align-items: center;
              text-align: center;
              // margin-right: 15px;

              .item {
                flex: 1;
                flex-direction: column;
                // justify-content: flex-end;
                align-items: center;
                user-select: none;
                // margin-left: 40px;
                cursor: pointer;

                .elimage {
                  width: 57px;
                  height: 57px;
                  border-radius: 50%;
                }

                .name {
                  font-size: 16px;
                  font-weight: 500;
                  color: #666666;
                  white-space: nowrap;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      padding-right: 20px;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }

    ::v-deep .el-dialog {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .virtualsimulation {
    .itemcontain {
      background: #ffffff;
      padding: 20px;

      .head {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 22px;

        .head-title {
          font-size: 20px;
          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .item {
        // height: 170px;
        width: 100%;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .elimage_img {
          background: aliceblue;
          min-height: 200px;
          width: 30%;

          .elimage_img_1 {
            width: 100%;
            text-align: center;
            margin: 0 auto;
            // padding-top: 5%;
          }
        }

        .item-right {
          height: 100%;
          // flex: 1;
          margin-left: 30px;
          width: 70%;

          .title {
            font-size: 20px;
            font-weight: 500;
            color: #222222;
          }

          .nameicon {
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              margin-right: 10px;
              width: 23px;
              height: 20px;
            }

            .name {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
            }
          }

          .bottomcontain {
            margin-top: 10px;
            height: 85px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .left {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;

              .contain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                top: -60px;

                .icon {
                  margin-right: 10px;
                  width: 22px;
                  height: 22px;
                }

                .name {
                  margin-right: 10px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333;
                }

                .time {
                  font-size: 15px;
                  font-weight: 400;
                  color: #222222;
                }
              }
            }

            .right {
              display: flex;
              // justify-content: flex-start;
              align-items: center;
              margin-right: 15px;

              .item {
                flex: 1;
                flex-direction: column;
                // justify-content: flex-end;
                align-items: center;
                user-select: none;
                margin-left: 40px;
                cursor: pointer;

                .elimage {
                  width: 57px;
                  height: 57px;
                  border-radius: 50%;
                }

                .name {
                  font-size: 16px;
                  font-weight: 500;
                  color: #666666;
                  white-space: nowrap;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      padding-right: 20px;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
}
</style>
